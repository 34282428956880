import { render, staticRenderFns } from "./DropdownTitle.vue?vue&type=template&id=e3fbd1d8&scoped=true&"
import script from "./DropdownTitle.vue?vue&type=script&setup=true&lang=ts&"
export * from "./DropdownTitle.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./DropdownTitle.vue?vue&type=style&index=0&id=e3fbd1d8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3fbd1d8",
  null
  
)

export default component.exports